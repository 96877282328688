/* eslint no-console:0 */
// This file is automatically compiled by vite, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/frontend and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= vite_javascript_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import './c-file_input';
import './dropdown';
import './flash_msg';
import JobDetailFloatingMenu from './job_detail_floating_menu';
import './job_favorite';
import './jobs_search';
import './vue_main';
import './cookie_favorite_button';
import { encodeCookieHistoryRecord } from './encode_cookie_history_record';

window.jQuery = $;
window.$ = $;

document.addEventListener('DOMContentLoaded', () => {
  // クッキーの`history_record`がエンコードされていない場合にエンコードする
  // TODO: リリースして1日以上経過したら削除する
  encodeCookieHistoryRecord();

  // TODO: フローティングメニューが表示されない画面でも読み込まれてるので、求人詳細用でだけ読みけ読み込むようにすると良さそう
  if (window.location.pathname !== '/') {
    new JobDetailFloatingMenu(800);
  }
});
